<script>
import router from "@/router";
import CampoTexto from '../../../components/campos_dados/campo_texto';
import CampoSelect from '../../../components/campos_dados/campo_select';
import TiposDeCampo from '../../../components/campos_dados/tipos_de_campo';
// import { fecharPagina } from '../../../functions/fechar_pagina';
import { camposBusca } from './campos_busca';
import { baseApiUrl, showError } from '@/global'
import axios from "axios";

const estadoBusca = {
  /** @type {boolean} */
  estadoAtual: false,

  exibir() {
    this.estadoAtual = true;
  },

  ocultar() {
    this.estadoAtual = false;
  },
};

const estadoOpcoesEdicao = {
  /** @type {boolean} */
  estadoAtual: false,

  exibir() {
    this.estadoAtual = true;
  },

  ocultar() {
    this.estadoAtual = false;
  },
};

const camposEdicaoGeral = {
  valorAdicional: new CampoTexto({
    nome: 'valor_adicional',
    label: 'Valor adicional (R$)',
    icone: 'fas fa-coins',
    valor: '',
    regras: [],
    numerico: true,
  }),
  prazoAdicional: new CampoTexto({
    nome: 'prazo_adicional',
    label: 'Prazo adicional (dias)',
    icone: 'fas fa-calendar',
    valor: '',
    regras: [],
  }),
};

const camposMaisOpcoesEdicao = {
  doca: new CampoSelect({
    nome: 'doca',
    label: 'Doca',
    icone: 'fas fa-folder',
    valor: '',
    itens: ['Doca 1', 'Doca 2', 'Doca 3', 'Doca 4', 'Doca 5'],
    regras: [],
  }),
  modal: new CampoSelect({
    nome: 'modal',
    label: 'Modal',
    icone: 'fas fa-th-list',
    valor: '',
    itens: ['Modal 1', 'Modal 2', 'Modal 3', 'Modal 4', 'Modal 5'],
    regras: [],
  }),

  marca: new CampoTexto({
    nome: 'marca',
    label: 'Marca',
    icone: 'fas fa-copyright',
    valor: '',
    regras: [],
  }),
  grupo: new CampoTexto({
    nome: 'grupo',
    label: 'Grupo',
    icone: 'fas fa-layer-group',
    valor: '',
    regras: [],
  }),
};

const estadoProdutos = {
  buscarProdutos() {
    let p = [];

    this.produtosOriginal.map((produto) => {
      if (
        camposBusca.descricao.valor == produto.descricao ||
        camposBusca.codIdentificacao.valor == produto.codigoIdentificacao ||
        camposBusca.doca.valor == produto.doca ||
        camposBusca.grupo.valor == produto.grupo ||
        camposBusca.marca.valor == produto.marca ||
        camposBusca.modal.valor == produto.modal
      ) p.push(produto);
    });

    this.produtos = p;
  }

};

function aoClicarEditarProduto(produto) {
  router.push({
    name: 'EditarProduto',
    params: { produto: produto },
  });
};

function aoClicarExcluirProduto(produto){
  let url = `${baseApiUrl}/produtos-excluir?idprodutos=${produto.idprodutos}`;

  this.$swal({
    title: 'Confirmação',
    text: `Deseja realmente excluir o produto ${produto.descricao}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    confirmButtonColor: '#4BB543',
    cancelButtonColor: '#d33',
    allowOutsideClick: false,
  }).then(async (res) => {
    if(res.isConfirmed){
      await axios
        .delete(url)
          .then(() => {
            this.$swal({
              title: 'Sucesso!!!',
              text: `Produto excluído com sucesso!`,
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            }).finally(() => (this.carregarProdutos()));
          })
          .catch(() => {
            this.$swal({
              title: 'Que pena!!!',
              text: `Não foi possível excluir o produto ${produto.codigo}.`,
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
    }
  })
};

function aoClicarAddProduto(){
  router.push({
    path: '/produtos/inserir',
  });
};

/** 
 * Campos com valores de venda nos canais de venda.
 * TODO: deve puxar os canais de venda da API.
 */
const camposValoresVenda = {
  valor1: new CampoTexto({
    nome: 'valor1',
    label: 'Madeira Madeira',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor2: new CampoTexto({
    nome: 'valor2',
    label: 'Netshoes',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor3: new CampoTexto({
    nome: 'valor3',
    label: 'Leroy Merlin',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor4: new CampoTexto({
    nome: 'valor4',
    label: 'Via Varejo',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor5: new CampoTexto({
    nome: 'valor5',
    label: 'Convertize',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor6: new CampoTexto({
    nome: 'valor6',
    label: 'Carrefour',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor7: new CampoTexto({
    nome: 'valor7',
    label: 'Conecta',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor8: new CampoTexto({
    nome: 'valor8',
    label: 'Renner',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [], 
    numerico: true,
  }),
};

function mudarWrap(){
  var text = document.getElementById("textWrap");
  if(text.style.whiteSpace == 'nowrap'){
    text.style.whiteSpace = 'normal';
  }else{
    text.style.whiteSpace = 'nowrap';
  }
}

export default {
  name: 'ListarProdutos',

  data: () => ({
    grid: {
      produtos: {
        headers: [
          { text: "Código", value: "idenf" },
          { text: "Descrição", value: "descricao"},
          { text: "Marca", value: "marca"},
          { text: "Grupo", value: "grupo"},
          { text: "Ações", value: "acoes", align: "center", sortable: false}
        ],
        items: [],
        carregando: false,
        totalRegistros: 0,
        paginaAtual: 1,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 50,
        itemsSelecionado: [],
        pesquisa: "",
      }
    },
    estadoBusca,
    camposBusca,
    camposEdicaoGeral,
    TiposDeCampo,
    estadoProdutos,
    estadoOpcoesEdicao,
    camposMaisOpcoesEdicao,
    camposValoresVenda
  }),

  created() {
    this.carregarProdutos();
  },

  methods: {
    async carregarProdutos(){
      this.grid.produtos.carregando = true;
      this.grid.produtos.items = [];
      let url = `${baseApiUrl}/produtos-listar?page=${this.grid.produtos.paginaAtual}&por_pagina=${this.grid.produtos.porPagina}`;

      if(this.camposBusca.codIdentificacao.valor != null && this.camposBusca.codIdentificacao.valor != ''){
        url += `&idenf=${this.camposBusca.codIdentificacao.valor}`;
      }

      if(this.camposBusca.descricao.valor != null && this.camposBusca.descricao.valor != ''){
        url += `&descricao=${this.camposBusca.descricao.valor}`;
      }

      if(this.camposBusca.modal.valor != null && this.camposBusca.modal.valor != ''){
        url += `&modal=${this.camposBusca.modal.valor}`;
      }

      if(this.camposBusca.marca.valor != null && this.camposBusca.marca.valor != ''){
        url += `&marca=${this.camposBusca.marca.valor}`;
      }

      if(this.camposBusca.grupo.valor != null && this.camposBusca.grupo.valor != ''){
        url += `&grupo=${this.camposBusca.grupo.valor}`;
      }

      if(this.camposBusca.doca.valor != null && this.camposBusca.doca.valor != ''){
        url += `&doca=${this.camposBusca.doca.valor}`;
      }

      await axios
        .get(url)
        .then((res) => {
          this.grid.produtos.items = res.data.dados.data;
          this.grid.produtos.totalRegistros = res.data.dados.total;
        })
        .catch(() => {
          showError("Não foi possível consultar os pedidos");
        })
        .finally(() => (this.grid.produtos.carregando = false));
    },

    mudarPagina(pagina){
      this.grid.produtos.paginaAtual = pagina;
      this.carregarProdutos();
    },

    alteracaoMultipla(produtos){
      let produtosText = "";
      let produtosFinal = "";
      for(let cont = 0; cont < produtos.length; cont++){
        produtosText += produtos[cont].idenf;
        produtosFinal += produtos[cont].idprodutos;

        if (cont+1 < produtos.length) {
          produtosFinal += ',';
          produtosText += ', ';
        }
      }

      this.$swal({
        title: 'Confirmação',
        html: `Tem certeza que deseja alterar os produtos:<br>${produtosText}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        customClass: {
          htmlContainer: 'text-wrap',
        },
      }).then((res) => {
        if(res.isConfirmed){
          this.estadoOpcoesEdicao.estadoAtual = false;
          this.grid.produtos.itemsSelecionado = [];
        }
      })
    },

    exclusaoMultipla(produtos){
      let produtosFinal = "";
      let produtosText = "";
      for(let cont = 0; cont < produtos.length; cont++){
        produtosText += produtos[cont].idenf;
        produtosFinal += produtos[cont].idprodutos;

        if (cont+1 < produtos.length) {
          produtosFinal += ',';
          produtosText += ', ';
        }
      }

      let url = `${baseApiUrl}/produtos-excluir?idprodutos=${produtosFinal}`;

      this.$swal({
        title: 'Confirmação',
        html: `Tem certeza que deseja excluir os produtos:<br>${produtosText}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        customClass: {
          htmlContainer: 'text-wrap',
        },
      }).then(async(res) => {
        if(res.isConfirmed){
          await axios
            .delete(url)
              .then(() => {
                this.$swal({
                  title: 'Sucesso!!!',
                  text: `Produtos excluídos com sucesso!`,
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                }).finally(() => (this.carregarProdutos()));
              })
              .catch(() => {
                this.$swal({
                  title: 'Que pena!!!',
                  text: `Não foi possível excluir os produtos ${produtosText}.`,
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              })
        }
      });
    },

    limparFiltro() {
      this.camposBusca.codIdentificacao.valor = '';
      this.camposBusca.descricao.valor = '';
      this.camposBusca.modal.valor = '';
      this.camposBusca.marca.valor = '';
      this.camposBusca.grupo.valor = '';
      this.camposBusca.doca.valor = '';
    },

    fecharPagina(){
      this.$router.push("/");
    },

    mudarWrap,
    aoClicarAddProduto,
    aoClicarExcluirProduto,
    aoClicarEditarProduto,
  },

  watch: {
    "grid.produtos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.produtos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.produtos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
  }
}

</script>

<template>
  <article>
    <!-- Título. -->
    <v-toolbar dense flat>
      <v-app-bar-nav-icon>
        <v-icon>fas fa-box</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Produtos</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click="() => aoClicarAddProduto()" class="mr-2" width="200" color="#018454" style="color: white;">
        <v-icon class="mr-2" color="white">fas fa-plus</v-icon>
        Adicionar
      </v-btn>

      <v-app-bar-nav-icon @click="() => {limparFiltro(), fecharPagina()}">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <v-row dense align="center" justify="center" class="mt-4" style="position: relative;">
      <div v-if="grid.produtos.itemsSelecionado.length > 1" style="position: absolute; left: 10px;">
        <v-btn type="button" color="#757575" @click="estadoOpcoesEdicao.exibir()"
          style="color: #FFFFFF;" class="mr-2" width="fit-content">
          <v-icon small dense class="mx-2">fas fa-sliders-h</v-icon>
          Modificar Selecionados
        </v-btn>

        <v-btn @click="exclusaoMultipla(grid.produtos.itemsSelecionado)" type="button" color="#FF5252" style="color: #FFFFFF;" width="fit-content">
          <v-icon small dense class="mx-2">fas fa-trash-alt</v-icon>
          Excluir Selecionados
        </v-btn>
      </div>

      <v-card-title class="text-h6 pa-2">Listagem de Produtos</v-card-title>

      <div style="position: absolute; right: 10px;">
        <v-btn width="200" type="button" color="#FF5252" @click="() => { limparFiltro(), carregarProdutos() }"
          style="color: #FFFFFF;" class="mr-2">
          <v-icon dense class="mx-2">fas fa-broom</v-icon>
          Limpar
        </v-btn>
        
        <v-btn @click="estadoBusca.exibir()" width="200" color="#018454" style="color: white;">
          <v-icon small class="mr-2" color="white">fas fa-search</v-icon>
          Filtrar
        </v-btn>        
      </div>
    </v-row>

    <v-dialog v-model="estadoOpcoesEdicao.estadoAtual">
      <v-card outlined style="padding-top: 20px;">
        <div class="d-flex">
          <v-card-title>Modificar selecionados</v-card-title>
          <v-spacer></v-spacer>

          <v-btn class="mr-2" icon @click="estadoOpcoesEdicao.ocultar()">
            <v-icon color="#ff0000">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-card-text style="padding-top: 10px;">
          <v-card outlined>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="6" md="3" v-for="campo in camposMaisOpcoesEdicao" :key="campo.nome">
                  <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>

                  <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                    autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                    @change="campo.aoMudar">
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Valor de venda. -->
          <v-card outlined style="margin-top: 10px;">
            <v-card-title class="subtitle-1">Valor de venda (adicional ou negativo)</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="6" sm="3" md="3" v-for="campo in camposValoresVenda" :key="campo.nome">
                  <p>{{ campo.label }}</p>
                  <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" autocomplete="off" v-model="campo.valor" :rules="campo.regras" color="#018656"
                    @change="campo.aoMudar">
                  </v-text-field>

                  <v-select outlined dense append-icon="fas fa-type" :name="campo.nome" label="Tipo de valor"
                    autocomplete="off" :items="['Porcentagem', 'Moeda']" color="#018656" @change="campo.aoMudar">
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row justify="end" dense style="margin-top: 10px;">
            <v-col cols="12" sm="6" md="3">
              <v-btn type="button" color="#018656" @click="alteracaoMultipla(grid.produtos.itemsSelecionado)"
                style="color: #FFFFFF; width: 100%; height: 38px;">
                <v-icon dense class="mx-2">fas fa-check-circle</v-icon>
                SALVAR SELECIONADOS
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="estadoBusca.estadoAtual">
      <v-card outlined style="padding-top: 20px;" class="pa-2">
        <v-card-title>Buscar produtos</v-card-title>
        <v-card outlined>
          <v-card-text style="padding-top: 10px;">
            <v-row dense>
              <v-col cols="12" sm="6" md="3" v-for="campo in camposBusca" :key="campo.nome">
                <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                  :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                  :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                  color="#018656" @change="campo.aoMudar">
                </v-text-field>

                <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select && campo.nome == 'modal'" @click="campo.aoClicar"
                  :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                  autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                  @change="campo.aoMudar" item-text="nome" item-value="codigo">
                </v-select>

                <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select && campo.nome == 'doca'" @click="campo.aoClicar"
                  :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                  autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                  @change="campo.aoMudar">
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="ma-1 mt-3" justify="end">
          <v-btn width="250" type="button" color="#FF5252" @click="() => limparFiltro()"
            style="color: #FFFFFF;" class="mr-2">
            <v-icon dense class="mx-2">fas fa-broom</v-icon>
            Limpar
          </v-btn>

          <v-btn width="250" type="button" color="#018656" @click="() => { carregarProdutos(), estadoBusca.estadoAtual = false }"
            style="color: #FFFFFF;">
            <v-icon dense class="mx-2">fas fa-search</v-icon>
            BUSCAR
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-card outlined style="margin-top: 10px;">
      <v-card-title>
        <v-text-field class="mx-4" color="#018656" v-model="grid.produtos.pesquisa" prepend-icon="fas fa-search"
          label="Pesquisa rápida" single-line hide-details clearable>
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row dense style="height: calc(100vh - 376px);" class="overflow-y-auto">
          <v-col>
            <v-data-table :headers="grid.produtos.headers" :items="grid.produtos.items" :loading="grid.produtos.carregando" 
            v-model="grid.produtos.itemsSelecionado" dense fixed-header show-select color="#018454" sort-by="idenf"
            item-key="idprodutos" :search="grid.produtos.pesquisa" hide-default-footer :items-per-page="grid.produtos.porPagina">

              <template #item.acoes="{ item }">
                <div class="d-flex justify-center">
                  <v-btn type="button" icon class="mr-2" @click="() => aoClicarEditarProduto(item)">
                    <v-icon small color="primary">fas fa-pen</v-icon>
                  </v-btn>
                  <v-btn icon type="button" @click="() => aoClicarExcluirProduto(item)">
                    <v-icon small color="red">fas fa-trash</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row style="margin-top: 15px; margin-bottom: 0px; position: relative;">
          <v-col cols="8" style="max-height: 65px; position: absolute; left: 0; right: 0; margin: auto;">
            <v-pagination color="#018656" style="color: #018656" v-model="grid.produtos.paginaAtual" @input="mudarPagina"
            :length="Math.ceil(grid.produtos.totalRegistros / grid.produtos.porPagina)" :total-visible="5"></v-pagination>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="d-none d-md-flex mr-2" cols="2" style="max-height: 65px;">
            <v-select color="#018656" dense outlined label="Resultados por página" :items="grid.produtos.qtdsPaginacao"
              item-text="text" item-value="value" v-model="grid.produtos.porPagina"></v-select>
          </v-col>
        </v-row>      
      </v-card-text>
    </v-card>
  </article>
</template>

<style>
.text-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 87%;
  justify-self: center;
  margin-bottom: 0px;
}

.textStyle{
  color: #2196f3;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>